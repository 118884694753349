import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import deepEqual from 'utils/equals';

interface FormValues {
  startDate: string | null;
  endDate: string | null;
  startPrice: string | null;
  endPrice: string | null;
  booked: boolean | null;
  bookable: boolean | null;
  closed: boolean | null;
  full: boolean | null;
  [key: string]: any;
}

export interface EventFilter {
  startDate: Date | null;
  endDate: Date | null;
  startPrice: number | null;
  endPrice: number | null;
  booked: boolean;
  bookable: boolean;
  closed: boolean;
  full: boolean;
  categories: string[];
}

const initial: EventFilter = {
  startDate: null,
  endDate: null,
  startPrice: null,
  endPrice: null,
  booked: false,
  bookable: false,
  closed: false,
  full: false,
  categories: [],
};

const useEventFilter = () => {
  const [search, setSearch] = useState('');
  const [eventFilter, setEventFilter] = useState<EventFilter>(initial);
  const { control, watch, reset } = useForm<FormValues>({ mode: 'onSubmit' });
  const watchFields = watch();

  const changeFilter = () => {
    const categories: string[] = [];

    Object.entries(watchFields).forEach(([key, value]) => {
      if (!Number.isNaN(Number(key)) && value) categories.push(key);
    });

    let startDatetime = null;
    let endDatetime = null;

    if (watchFields.startDate) {
      startDatetime = new Date(Date.parse(watchFields.startDate));
      startDatetime.setHours(0, 0, 0, 0);
    }

    if (watchFields.endDate) {
      endDatetime = new Date(Date.parse(watchFields.endDate));
      endDatetime.setHours(0, 0, 0, 0);
    }

    const filter: EventFilter = {
      startDate: startDatetime,
      endDate: endDatetime,
      startPrice: watchFields.startPrice ? parseInt(watchFields.startPrice, 10) : null,
      endPrice: watchFields.endPrice ? parseInt(watchFields.endPrice, 10) : null,
      booked: watchFields.booked || false,
      bookable: watchFields.bookable || false,
      closed: watchFields.closed || false,
      full: watchFields.full || false,
      categories,
    };

    if (!deepEqual(filter, eventFilter)) {
      setEventFilter(filter);
    }
  };

  const resetFilter = () => {
    reset();
  };

  useEffect(() => {
    changeFilter();
  }, [watchFields]);

  return { eventFilter, control, resetFilter, search, setSearch };
};

export default useEventFilter;
