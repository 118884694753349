import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import CheckComponent from '/components/form/Check';
import DateComponent from '/components/form/Date';
import TextComponent from '/components/form/Text';

const Root = styled('div')(({ theme }) => ({
  padding: '8px 0px',
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    padding: '8px 16px',
  },
}));

const Wrappable = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrap',
})(({ wrap }: { wrap: boolean }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: wrap ? 'wrap' : undefined,
}));

const LeftInterval = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrap',
})(({ wrap }: { wrap: boolean }) => ({
  marginRight: !wrap ? '18px' : undefined,
  width: '100%',
}));

const RightInterval = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrap',
})(({ wrap }: { wrap: boolean }) => ({
  marginLeft: !wrap ? '18px' : undefined,
  width: '100%',
}));

interface Props {
  control: Control<any, any>;
  status: { label: string; value: string }[];
  category: any;
  isMobile?: boolean;
  closeOverlay?: () => void;
}

/**
 * EventFilter
 *
 * @param control control from useForm
 * @param status status checkboxes
 * @param category checkboxes
 * @param isMobile will render certain components if it's mobile
 * @param closeOverlay function to close the overlay
 */
const EventFilter: React.FC<Props> = ({ control, status, category, isMobile, closeOverlay }) => {
  const targetRef = useRef<any>();
  const [width, setWidth] = useState(320);

  const shouldWrap = width < 280;

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth((targetRef?.current as any).offsetWidth || 320);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      <Root ref={targetRef}>
        <Typography variant="h6">Date</Typography>
        <Wrappable wrap={shouldWrap}>
          <LeftInterval wrap={shouldWrap}>
            <DateComponent control={control} name="startDate" label="from" />
          </LeftInterval>
          {!shouldWrap ? <div>-</div> : null}
          <RightInterval wrap={shouldWrap}>
            <DateComponent control={control} name="endDate" label="to" />
          </RightInterval>
        </Wrappable>

        <Typography variant="h6" style={{ marginTop: '0.5rem' }}>
          Price
        </Typography>
        <Wrappable wrap={shouldWrap}>
          <LeftInterval wrap={shouldWrap}>
            <TextComponent control={control} name="startPrice" label="from" postfix="SEK" style={{ margin: 0 }} />
          </LeftInterval>
          {!shouldWrap ? <div>-</div> : null}
          <RightInterval wrap={shouldWrap}>
            <TextComponent control={control} name="endPrice" label="to" postfix="SEK" style={{ margin: 0 }} />
          </RightInterval>
        </Wrappable>

        <div>
          <Typography variant="h6" style={{ marginTop: '0.5rem' }}>
            Status
          </Typography>
          {status.map((stat) => (
            <CheckComponent
              key={`check-status-${stat.value}}`}
              name={stat.value}
              control={control}
              label={
                <Typography variant="body2">{stat.label.charAt(0).toUpperCase() + stat.label.slice(1)}</Typography>
              }
              noMargin
            />
          ))}
        </div>

        <div style={{ marginTop: '12px' }}>
          <Typography variant="h6">
            Category{' '}
            <Typography variant="body2" component="span">
              (booked/limit)
            </Typography>
          </Typography>
          {category.map((stat: any) => (
            <CheckComponent
              key={`check-category-${stat.value}`}
              name={stat.value}
              control={control}
              label={
                <Typography variant="body2">
                  {stat.label}{' '}
                  {stat.ticketLimit !== 0 ? (
                    <span style={{ opacity: 0.6 }}>
                      ({stat.current}/{stat.ticketLimit})
                    </span>
                  ) : null}
                </Typography>
              }
              noMargin
            />
          ))}
        </div>
        {isMobile ? (
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            style={{ marginTop: '40px', marginBottom: '40px' }}
            onClick={closeOverlay}
          >
            Show events
          </Button>
        ) : null}
      </Root>
    </>
  );
};

export default EventFilter;
