import React from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleIcon from '@mui/icons-material/People';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from '@mui/material';

const iconStyle: React.CSSProperties = {
  width: '14px',
  height: '14px',
  marginRight: '4px',
};

interface Props {
  icon: 'PEOPLE' | 'PRICE' | 'SCHEDULE' | 'PLACE';
  text: string;
}

const EventStatisticsData: React.FC<Props> = ({ icon, text }) => {
  const renderIcon = () => {
    switch (icon) {
      case 'PEOPLE':
        return <PeopleIcon style={iconStyle} />;
      case 'PRICE':
        return <CreditCardIcon style={iconStyle} />;
      case 'SCHEDULE':
        return <ScheduleIcon style={iconStyle} />;
      case 'PLACE':
        return <PlaceIcon style={iconStyle} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {renderIcon()}
      <Typography noWrap variant="caption" component="span">
        {text}
      </Typography>
    </div>
  );
};

export default EventStatisticsData;
