import SubpageModal from 'components/subpageModal/SubpageModal';
import useIsMobile from 'hooks/useIsMobile';
import { EventGroup } from 'queries/event/user-event-groups';
import React, { useEffect, useState } from 'react';
import { UserEvent, UserTicket } from 'types/types';
import EventFilter from './EventFilter';
import EventFilterHeader from './EventFilterHeader';

interface EventFilterControllerProps {
  eventFilter: any;
  events: UserEvent[];
  tickets: UserTicket[];
  eventGroups: EventGroup[];
  filterOverlay: boolean;
  closeOverlay: () => void;
}

interface Category {
  value: string;
  current: number;
  ticketLimit: number;
  label: string;
}

/**
 * The main event filter controlling.
 * Render and functionality
 * events, tickets, eventGroups are required
 */
const EventFilterController: React.FC<EventFilterControllerProps> = ({
  eventFilter,
  events,
  tickets,
  eventGroups,
  filterOverlay,
  closeOverlay,
}) => {
  const isMobile = useIsMobile();
  /* 
    Checkbox options and their values
    FIXME: this should probably come from backend 
  */
  const status = [
    { value: 'booked', label: 'Booked' },
    { value: 'bookable', label: 'Bookable' },
    { value: 'full', label: 'Fully booked' },
    { value: 'closed', label: 'Closed for booking' },
  ];
  const [category, setCategory] = useState<Category[]>([]);

  /**
   * Get 'event groups' / categories and transform them into checkboxes
   * Only show value that the user has
   */
  useEffect(() => {
    const categories: Category[] = [];
    eventGroups.forEach((value) => {
      if (value.isBroadcasted && events.filter((event) => event.eventGroups.includes(value.id)).length > 0) {
        categories.push({
          value: value.id.toString(),
          current: tickets.filter((ticket) => ticket.eventGroups.includes(value.id)).length,
          ticketLimit: value.ticketLimit,
          label: value.name,
        });
      }
    });

    setCategory(categories);
  }, [tickets, events, eventGroups]);

  /**
   * Close overlay
   */
  const closeOverlayButton = () => {
    closeOverlay();
  };

  return (
    <>
      {!isMobile ? (
        <>
          <EventFilterHeader resetFilter={eventFilter.resetFilter} />
          <EventFilter control={eventFilter.control} status={status} category={category || []} />
        </>
      ) : (
        <SubpageModal
          title="Filters"
          maxWidth={918}
          isOpen={filterOverlay}
          reset={eventFilter.resetFilter}
          close={closeOverlayButton}
          hideHeader={false}
          hideReset={false}
        >
          <EventFilter
            control={eventFilter.control}
            status={status}
            category={category || []}
            isMobile
            closeOverlay={closeOverlayButton}
          />
        </SubpageModal>
      )}
    </>
  );
};

export default EventFilterController;
