import { Button, styled } from '@mui/material';
import React from 'react';

const StyledEventButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'booked',
})<{ booked: boolean }>(({ theme, booked }) => ({
  backgroundColor: booked ? theme.palette.error.main : undefined,
  '&:hover': {
    backgroundColor: booked ? theme.palette.error.dark : undefined,
  },
}));

interface Props {
  booked: boolean;
  label: string;
  [key: string]: any;
}

const EventButton: React.FC<Props> = ({ booked, label, ...props }) => {
  return (
    <StyledEventButton booked={booked} color="secondary" variant="contained" {...props}>
      {label}
    </StyledEventButton>
  );
};

export default EventButton;
