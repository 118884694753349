import React from 'react';

import EventStatisticsData from './EventStatisticsData';

interface Props {
  location: string;
  attendanceText: string;
  timeText: string;
  priceText: string;
}

const EventStatisticsMobile: React.FC<Props> = ({ location, attendanceText, timeText, priceText }) => {
  return (
    <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ flex: 1, width: '50%' }}>
        <EventStatisticsData icon="PLACE" text={location} />
        <EventStatisticsData icon="PEOPLE" text={attendanceText} />
      </div>
      <div style={{ flex: 1, width: '50%' }}>
        <EventStatisticsData icon="SCHEDULE" text={timeText} />
        <EventStatisticsData icon="PRICE" text={priceText} />
      </div>
    </td>
  );
};

export default EventStatisticsMobile;
