import React, { useEffect } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { useForm } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  openOverlay: () => void;
  setEventSearch: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Search Bar for events page
 */
const EventSearch: React.FC<Props> = ({ openOverlay, setEventSearch }) => {
  const theme = useDynamicTheme();
  const { control, watch } = useForm({ mode: 'onSubmit' });

  const watchSearch = watch('search');

  useEffect(() => {
    if (watchSearch === undefined) return;
    setEventSearch(watchSearch);
  }, [watchSearch]);

  const open = () => {
    openOverlay();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextComponent control={control} name="search" label="Search event" />
      {useMediaQuery(theme.breakpoints.down('md')) ? (
        <IconButton style={{ margin: '0 8px' }} onClick={open} size="large">
          <FilterListIcon style={{ width: '24px', height: '24px', color: theme.palette.secondary.main }} />
        </IconButton>
      ) : null}
    </div>
  );
};

export default EventSearch;
