import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Theme, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((t: Theme) => ({
  rootMobile: {
    margin: '0 16px',
    borderBottom: '1px solid #0000001E',
  },
  root: {
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowBackIcon: {
    width: '24px',
    height: '24px',
    color: t.palette.secondary.main,
  },
  button: {
    color: t.palette.secondary.main,
  },
  icon: {
    marginRight: '8px',
    padding: 0,
  },
}));

interface Props {
  resetFilter: () => void;
  closeOverlay?: () => void;
  isMobile?: boolean;
}

/**
 * Header for the event filter
 *
 * @param resetFilter function to reset the filters
 * @param closeOverlay closes the overlay
 * @param isMobile renders certain components based on if the boolean is true
 */
const EventFilterHeader: React.FC<Props> = ({ resetFilter, closeOverlay, isMobile }) => {
  const classes = useStyles();

  return (
    <div className={isMobile ? `${classes.root} ${classes.rootMobile}` : classes.root}>
      <div className={classes.title}>
        {isMobile ? (
          <IconButton className={classes.icon} onClick={closeOverlay} size="large">
            <ArrowBackIcon className={classes.arrowBackIcon} />
          </IconButton>
        ) : null}
        <Typography variant="h5">Filters</Typography>
      </div>
      <div>
        <Button variant="text" color="secondary" onClick={resetFilter} className={classes.button}>
          reset
        </Button>
      </div>
    </div>
  );
};

export default EventFilterHeader;
