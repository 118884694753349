import React from 'react';

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import EventStatisticsDesktop from './EventStatisticsDesktop';
import EventStatisticsMobile from './EventStatisticsMobile';

interface Props {
  isMobile?: boolean;
  location?: string;
  attendanceLimit?: number;
  startTime?: Date;
  endTime?: Date;
  price?: number;
}

const getAttendanceText = (attendanceLimit: number) => {
  if (attendanceLimit === 0) return 'No limit';
  return `${attendanceLimit} people`;
};

const getPriceText = (price: number) => {
  return `${price} SEK`;
};

const EventStatistics: React.FC<Props> = ({
  isMobile = false,
  location = '',
  attendanceLimit = 0,
  startTime = new Date(),
  endTime = new Date(),
  price = 0,
}) => {
  const time = `${format(utcToZonedTime(startTime, 'Europe/Stockholm'), 'HH:mm')} - ${format(
    utcToZonedTime(endTime, 'Europe/Stockholm'),
    'HH:mm'
  )}`;

  const priceText = getPriceText(price);
  const attendanceText = getAttendanceText(attendanceLimit);

  return (
    <table style={{ width: '100%', tableLayout: 'fixed' }}>
      <tbody>
        <tr>
          {!isMobile ? (
            <EventStatisticsDesktop
              location={location}
              attendanceText={attendanceText}
              timeText={time}
              priceText={priceText}
            />
          ) : (
            <EventStatisticsMobile
              location={location}
              attendanceText={attendanceText}
              timeText={time}
              priceText={priceText}
            />
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default EventStatistics;
