import * as Backend from 'queries/BackendRequest';
import { UserEvent } from 'types/types';

const fetchEvents = async (pid: string): Promise<UserEvent[]> => {
  const result = await Backend.request<UserEvent[]>('get', `/event/${pid}`, {});
  return result.data.data;
};

export const getEventsQuery = (pid: number | string) => ({
  queryKey: ['getEventsQuery', pid],
  queryFn: () => fetchEvents(pid as string),
  enabled: !!pid,
});

export default getEventsQuery;
