import React from 'react';

import EventStatisticsData from './EventStatisticsData';

interface Props {
  location: string;
  attendanceText: string;
  timeText: string;
  priceText: string;
}

const EventStatisticsDesktop: React.FC<Props> = ({ location, attendanceText, timeText, priceText }) => {
  return (
    <>
      <td>
        <EventStatisticsData icon="PLACE" text={location} />
      </td>
      <td>
        <EventStatisticsData icon="PEOPLE" text={attendanceText} />
      </td>
      <td>
        <EventStatisticsData icon="SCHEDULE" text={timeText} />
      </td>
      <td>
        <EventStatisticsData icon="PRICE" text={priceText} />
      </td>
    </>
  );
};

export default EventStatisticsDesktop;
